<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <div class="mb-2">
            <h4 class="font-size-16 text-gray-6">{{title}}</h4>
          </div>
          <div class="mb-4">
            <a-table
              :columns="columns"
              :dataSource="users"
              :class="$style.table"
              :pagination="pagination"
            >
            <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                Search
              </a-button>
              <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
                Reset
              </a-button>
            </div>
            <template slot="filterIcon" slot-scope="filtered">
              <a-icon
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
            </template>
              <template slot="action" slot-scope="text, record">
                <div class="text-nowrap">
                  <button type="button" class="btn btn-outline-danger mr-2 mb-2" @click="activate(record)">Activate</button>
                  <button type="button" class="btn btn-outline-primary mr-2 mb-2" @click="goToSingleUser(record)">View Details</button>
                </div>
              </template>

              <template slot="userType" slot-scope="text">
                <span class="text-capitalize">{{removeUnderScore(text)}}</span>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const columns = [
  {
    title: 'First Name',
    dataIndex: 'first_name',
    className: 'text-gray-6',
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'firstName' 
    },
    onFilter: (value, record) =>
    {    
      if(record.first_name){
        return record.first_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    className: 'text-gray-6',
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'lastName' 
    },
    onFilter: (value, record) =>
    {    
      if(record.last_name){
        return record.last_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    className: 'text-left text-gray-6',
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'email' 
    },
    onFilter: (value, record) =>
    {    
      if(record.email){
        return record.email
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    className: 'text-left text-gray-6',
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'phone' 
    },
    onFilter: (value, record) =>
    {    
      if(record.phone){
        return record.phone
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: 'User Type',
    dataIndex: 'user_type',
    className: 'text-left text-gray-6 text-capitalize',
    scopedSlots: { customRender: 'userType' },
    filters: [
      { text: 'Tourist', value: 'tourist' },
      { text: 'Tour Sight', value: 'tour_sight' },
      { text: 'Tour Operator', value: 'tour_operator' },
      { text: 'Hotel and Accomodations', value: 'hotel_and_accommodations' },
      { text: 'Food and Drinks', value: 'food_and_drinks' },
      { text: 'Events', value: 'events' },
      { text: 'Transport', value: 'transport' },
    ],
    onFilter: (value, record) => record.user_type.indexOf(value) === 0,
  },
  {
    dataIndex: 'action',
    className: 'text-right',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'rejectedUsersTable',
  data: function () {
    return {
      columns,
      selectedRowKeys: [],
      title: 'Rejected Users',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      pagination: {
        pageSize: 20
      },
    }
  },
  async mounted() {
    await this.getAllUsers()
  },
  methods: {
    ...mapActions('admin', {
      getUsers: 'GET_REJECTED_USERS',
      activateUser: 'ACTIVATE_USER',
    }),
    async getAllUsers() {
      await this.getUsers()
    },
    async activate(record) {
      await this.activateUser(record._id)
      // await this.getAllUsers()
    },
    goToSingleUser(record) {
      this.$router.push({ name: 'user', params: { user_id: record._id, user_type: record.user_type } })
    },
    removeUnderScore(word) {
      const result = word.replace(/_/g," ");
      return result
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
  },
  computed: {
    ...mapGetters('admin',["users"]),
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
